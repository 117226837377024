import React, { useState } from 'react';
import {
	Box,
	Center,
    Flex,
    Spacer,
    HStack,
    VStack,
    Image,
    Text,
    Wrap,
    WrapItem,
    Link,
    Tooltip,
    useToast,
} from "@chakra-ui/react"

import { XIcon, GIcon, EmailIcon, ZhihuIcon, GithubIcon, IGIcon, ColabIcon } from '../../icons'

function Bio(props) {

    const [isHoverX, setIsHoverX] = useState(false);
    const [isHoverG, setIsHoverG] = useState(false);
    const [isHoverZ, setIsHoverZ] = useState(false);
    const [isHoverGit, setIsHoverGit] = useState(false);
    const [isHoverIG, setIsHoverIG] = useState(false);
    const [isHoverEmail, setIsHoverEmail] = useState(false);
    const sfs = props.fontSizes['small'];


    const email = "example@example.com"; // Replace with your actual email address
    const toast = useToast();

    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText(email)
            .then(() => {
                // Show success toast
                toast({
                    title: "Success",
                    description: "Email address copied to clipboard!",
                    status: "success",
                    duration: 1000,
                    isClosable: true,
                    position: "top",
                });
            })
            .catch(err => {
                // Show error toast
                toast({
                    title: "Error",
                    description: "Failed to copy email to clipboard.",
                    status: "error",
                    duration: 1000,
                    isClosable: true,
                    position: "top",
                });
            });
    };

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
            fontFamily="Catamaran"
        >
            <Box
                h="100%"
                w="100%"
                maxWidth="928px"
                // bg="#000000"
                margin="0px 24px 0 24px"
            >
                <Flex>
                    <Box position="relative" margin="20px 5px 0 10px">
                        <VStack align="start"  height="100%">
                            <Text position="relative" fontSize='18px' lineHeight="24px" color="#FFFFFF" fontWeight="500" fontFamily="Catamaran">
                                Ph.D. (<Link href="https://www.ece.cornell.edu/ece" isExternal color="teal.300">Cornell</Link>) 
                            </Text>
                            <Text position="relative" fontSize='18px' lineHeight="24px" color="#FFFFFF" fontWeight="500" fontFamily="Catamaran">
                                M.Phil. (<Link href="https://www.cse.cuhk.edu.hk/" isExternal color="teal.300">CUHK</Link>)
                            </Text>
                            <Text position="relative" fontSize='18px' lineHeight="24px" color="#FFFFFF" fontWeight="500" fontFamily="Catamaran">
                                B.S. (<Link href="https://en.scu.edu.cn/" isExternal color="teal.300">SCU</Link>)
                            </Text>

                            <Box h="10px" w="100%" bg="RGBA(0,0,0,0)" />
                            <Wrap spacing="12px">
                                <WrapItem>
                                    <Link href="https://twitter.com/mirukuzhang" isExternal
                                        onMouseEnter={() => setIsHoverX(true)}
                                        onMouseLeave={() => setIsHoverX(false)}>
                                        <XIcon size="24" color={isHoverX ? "#EE7D85" : "#FFFFFF"} />
                                    </Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link href="https://scholar.google.com/citations?hl=en&view_op=list_works&gmla=AJsN-F6m-HrfXadW3HSnQM6TV7yVTiGJjTEqxga34TKTcpX3jscxILUvh9e2hpFoKjz2S4nc1CXNsR5T4QV6Vrnm0ZqWe15ywg&user=TKjuT30AAAAJ" isExternal
                                        onMouseEnter={() => setIsHoverG(true)}
                                        onMouseLeave={() => setIsHoverG(false)}>
                                        <GIcon size="24" color={isHoverG ? "#EE7D85" : "#FFFFFF"} />
                                    </Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link href="https://www.zhihu.com/people/mirukuzhang" isExternal
                                        onMouseEnter={() => setIsHoverZ(true)}
                                        onMouseLeave={() => setIsHoverZ(false)}>
                                        <ZhihuIcon size="24" color={isHoverZ ? "#EE7D85" : "#FFFFFF"} />
                                    </Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link href="https://github.com/tinymilky" isExternal
                                        onMouseEnter={() => setIsHoverGit(true)}
                                        onMouseLeave={() => setIsHoverGit(false)}>
                                        <GithubIcon size="24" color={isHoverGit ? "#EE7D85" : "#FFFFFF"} />
                                    </Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link href="https://www.instagram.com/tinymilky/" isExternal
                                        onMouseEnter={() => setIsHoverIG(true)}
                                        onMouseLeave={() => setIsHoverIG(false)}>
                                        <IGIcon size="24" color={isHoverIG ? "#EE7D85" : "#FFFFFF"} />
                                    </Link>
                                </WrapItem>
                            </Wrap>
                            <Box h="10px" w="100%" bg="RGBA(0,0,0,0)" />
                            <HStack>
                                <Tooltip label="Click to copy email" placement="bottom-end">
                                    <Box as="button" onClick={copyEmailToClipboard}
                                        onMouseEnter={() => setIsHoverEmail(true)}
                                        onMouseLeave={() => setIsHoverEmail(false)}
                                    >
                                        <EmailIcon size="24" color={isHoverEmail ? "#EE7D85" : "#FFFFFF"} />
                                    </Box>
                                </Tooltip>
                                <Text position="relative" fontSize={sfs} lineHeight="24px" color="#FFFFFF" fontWeight="400">
                                    hz459[at]cornell.edu
                                </Text>
                            </HStack>
                        </VStack>
                    </Box>
                    <Spacer />
                    <Image
                        margin="20px 10px 0 5px"
                        zIndex="1"
                        position="relative"
                        overflow="hidden"
                        w="350px"
                        // width={Math.min(width/2, 400).toString()+"px"}
                        // height="300px"
                        objectFit="cover"
                        src="./assets/SD_2022.jpg"
                        border="3px solid rgba(255, 255, 255, 0.1)" 
                        borderRadius="md" 
                        boxShadow="4px 4px 4px 4px rgba(0, 0, 0, 0.25)"
                    />
                </Flex>
                {/* <Box h="20px" w="100%" bg="RGBA(0,0,0,0)" /> */}
                <HStack margin="20px 10px 0 10px" alignItems="start">
                    <ColabIcon size="20" color="#EE7D85" />
                    <Text position="relative" fontSize="16px" lineHeight="20px" color="#FFFFFF" fontWeight="400" fontFamily="Catamaran">
                        Working on exciting AI research in medicine, if you are interested in collaborating, please reach out!
                    </Text>
                </HStack>
            </Box >
		</Center>
	)
}

export default Bio;