import React, { useState } from 'react';
import {
	Box,
	Center,
    Flex,
    Spacer,
    HStack,
    VStack,
    Image,
    Text,
    Wrap,
    WrapItem,
    Link,
    Tooltip,
    useToast,
  } from "@chakra-ui/react"
import { XIcon, GIcon, EmailIcon, ZhihuIcon, GithubIcon, IGIcon } from '../../icons'

const HitCounter = () => {
  return (
    <div className="statcounter">
      <a title="hit counter" href="http://statcounter.com/free-hit-counter/" target="_blank" rel="noopener noreferrer">
        <img className="statcounter" src="//c.statcounter.com/11149150/0/59193237/0/" alt="hit counter" />
      </a>
    </div>
  );
};

function Footer(props) {

    const [isHoverX, setIsHoverX] = useState(false);
    const [isHoverG, setIsHoverG] = useState(false);
    const [isHoverZ, setIsHoverZ] = useState(false);
    const [isHoverGit, setIsHoverGit] = useState(false);
    const [isHoverIG, setIsHoverIG] = useState(false);
    const [isHoverEmail, setIsHoverEmail] = useState(false);


    const email = "example@example.com"; // Replace with your actual email address
    const toast = useToast();

    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText(email)
            .then(() => {
                // Show success toast
                toast({
                    title: "Success",
                    description: "Email address copied to clipboard!",
                    status: "success",
                    duration: 1000,
                    isClosable: true,
                });
            })
            .catch(err => {
                // Show error toast
                toast({
                    title: "Error",
                    description: "Failed to copy email to clipboard.",
                    status: "error",
                    duration: 1000,
                    isClosable: true,
                });
            });
    };
	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
            h="150px"
        >
            <Box
                zIndex="4"  
                height="78px"
                maxWidth="928px"
                w="100%"
                // bg="#000000"
                margin="24px 24px 0 24px"
                borderTop="3px solid rgba(255, 255, 255, 0.1)"
            >
                <Flex margin="10px 0 0 10px">
                    <Box height="54px" position="relative">
                        <HStack spacing="10px">
                            <Tooltip label="Click to copy email" placement="bottom-end">
                                <Box as="button" onClick={copyEmailToClipboard}
                                    onMouseEnter={() => setIsHoverEmail(true)}
                                    onMouseLeave={() => setIsHoverEmail(false)}
                                >
                                    <EmailIcon size="24" color={isHoverEmail ? "#EE7D85" : "#FFFFFF"} />
                                </Box>
                            </Tooltip>
                            <Text position="relative" fontSize="16px" lineHeight="24px" color="#FFFFFF" fontWeight="400">
                                hz459[at]cornell.edu
                            </Text>
                        </HStack>
                    </Box>
                    <Spacer />
                    <Wrap spacing="12px">
                        <WrapItem>
                            <Link href="https://twitter.com/mirukuzhang" isExternal
                                onMouseEnter={() => setIsHoverX(true)}
                                onMouseLeave={() => setIsHoverX(false)}>
                                <XIcon size="24" color={isHoverX ? "#EE7D85" : "#FFFFFF"} />
                            </Link>
                        </WrapItem>
                        <WrapItem>
                            <Link href="https://scholar.google.com/citations?hl=en&view_op=list_works&gmla=AJsN-F6m-HrfXadW3HSnQM6TV7yVTiGJjTEqxga34TKTcpX3jscxILUvh9e2hpFoKjz2S4nc1CXNsR5T4QV6Vrnm0ZqWe15ywg&user=TKjuT30AAAAJ" isExternal
                                onMouseEnter={() => setIsHoverG(true)}
                                onMouseLeave={() => setIsHoverG(false)}>
                                <GIcon size="24" color={isHoverG ? "#EE7D85" : "#FFFFFF"} />
                            </Link>
                        </WrapItem>
                        <WrapItem>
                            <Link href="https://www.zhihu.com/people/mirukuzhang" isExternal
                                onMouseEnter={() => setIsHoverZ(true)}
                                onMouseLeave={() => setIsHoverZ(false)}>
                                <ZhihuIcon size="24" color={isHoverZ ? "#EE7D85" : "#FFFFFF"} />
                            </Link>
                        </WrapItem>
                        <WrapItem>
                            <Link href="https://github.com/tinymilky" isExternal
                                onMouseEnter={() => setIsHoverGit(true)}
                                onMouseLeave={() => setIsHoverGit(false)}>
                                <GithubIcon size="24" color={isHoverGit ? "#EE7D85" : "#FFFFFF"} />
                            </Link>
                        </WrapItem>
                        <WrapItem>
                            <Link href="https://www.instagram.com/tinymilky/" isExternal
                                onMouseEnter={() => setIsHoverIG(true)}
                                onMouseLeave={() => setIsHoverIG(false)}>
                                <IGIcon size="24" color={isHoverIG ? "#EE7D85" : "#FFFFFF"} />
                            </Link>
                        </WrapItem>
                    </Wrap>
                </Flex>
                <Box margin="10px 0 0 10px">
                    <HitCounter />
                </Box>
            </Box >
		</Center>
	)
}

export default Footer;