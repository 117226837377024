import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
	Box,
	Center,
    Flex,
    Spacer,
    HStack,
    VStack,
    Image,
    Text,
    Wrap,
    WrapItem,
    Link,
} from "@chakra-ui/react"

import papers from './papers.json';
import PaperList from './PaperList.js';

function SelectedPapers(props) {

    const lfs = props.fontSizes['large'];
    // const featured_papers = ['P6', 'P4','C18','C17','J9'];
    // const classic_papers = ['J8','J5','J2','C11','C6','C4','C2'];
    const recent_papers = ['P9','P8','P7','P3','J12','J11','C19'];

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
        >
            <Box
                h="100%"
                w="100%"
                maxWidth="928px"
                // bg="#000000"
                // borderTop="3px solid rgba(255, 255, 255, 0.1)"
                margin="36px 24px 0 24px"
            >
                <Text position="relative" fontSize={lfs} lineHeight="24px" color="#FFFFFF" fontWeight="500" margin="0 0 10px 10px">
                    Papers (<Link as={RouterLink}  to="/fullpapers" color="teal.200">Full List</Link>)
                </Text>
                <Box h="3px" w="100%" bg="rgba(255, 255, 255, 0.1)" />
                <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        2024
                    </Text>
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 20px 0px 30px" fontFamily="Catamaran">
                        <PaperList papers={papers} paperIds={recent_papers} />
                    </Box>
                </Box>
                {/* <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        Classic
                    </Text>
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 20px 0px 30px" fontFamily="Catamaran">
                        <PaperList papers={papers} paperIds={classic_papers} />
                    </Box>
                </Box> */}
            </Box >
		</Center>
	)
}

export default SelectedPapers;