import { extendTheme, ColorMode } from "@chakra-ui/react"

const fonts = {
    heading: "system-ui, sans-serif",
    subHeading: "system-ui, sans-serif",
    body: "system-ui, sans-serif",
    mono: "Menlo, monospace",
}

const overrides = {
    fonts,
}

export default extendTheme(overrides)