import React, { useEffect } from 'react';

const StatCounter = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = "https://www.statcounter.com/counter/counter.js";

    // Define global variables for StatCounter
    window.sc_project = 11814271;
    window.sc_invisible = 1;
    window.sc_security = "a2ff47d2";

    // Append script to body
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Return the noscript content for users with JavaScript disabled
  return (
    <noscript>
      <div className="statcounter">
        <a title="Web Analytics" href="https://statcounter.com/" target="_blank" rel="noopener noreferrer">
          <img className="statcounter" src="https://c.statcounter.com/11814271/0/a2ff47d2/1/" alt="Web Analytics" referrerPolicy="no-referrer-when-downgrade" />
        </a>
      </div>
    </noscript>
  );
};

export default StatCounter;
