import React, { useState } from 'react';
import {
	Box,
	Center,
    Flex,
    Spacer,
    HStack,
    Text,
} from "@chakra-ui/react"

import HeaderMenuButton from './HeaderMenuButton';

function Header(props) {

	const menu_items = {
		About: {
			width:"100px", 
			content:[["Home", "./"], ["Github", "https://github.com/tinymilky"], ["Zhihu", "https://www.zhihu.com/people/mirukuzhang"], ["Instagram", "https://www.instagram.com/tinymilky/"], ["Google Scholar", "https://scholar.google.com/citations?hl=en&view_op=list_works&gmla=AJsN-F6m-HrfXadW3HSnQM6TV7yVTiGJjTEqxga34TKTcpX3jscxILUvh9e2hpFoKjz2S4nc1CXNsR5T4QV6Vrnm0ZqWe15ywg&user=TKjuT30AAAAJ"], ["X", "https://twitter.com/mirukuzhang"]]
		},
		Publications: {
			width:"156px", 
			content:[["Full List", "./"]]
		},
	};

	const menuItems = Object.keys(menu_items).map((item) =>
		<HeaderMenuButton
			key={item} 
			name={item} 
			width={menu_items[item]["width"]} 
			content={menu_items[item]["content"]}
		/>
	);	

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
        >
            <Box
                zIndex="4"  
                height="78px"
                maxWidth="928px"
                w="100%"
                // bg="#000000"
                margin="24px 24px 0 24px"
                borderBottom="3px solid rgba(255, 255, 255, 0.1)"
            >
                <Flex margin="0 0 0 10px">
                    <Box width="165px" height="54px" position="relative">
                        <Text top="32px" position="absolute" fontSize="28px" lineHeight="24px" color="#FFFFFF" fontWeight="500">
                            Hang Zhang
                        </Text>
                    </Box>
                    <Spacer />
                    <Box zIndex="5" height="54px" position="relative">
                        <HStack >
                            {menuItems}
                        </HStack>
                    </Box>
                </Flex>
            </Box >
		</Center>
	)
}

export default Header;