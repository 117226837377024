import React from 'react';
import { Box} from "@chakra-ui/react"
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Header from "./pages/Header"
import Bio from "./pages/Bio"
import Intro from "./pages/Intro"
import SelectedPapers from "./pages/SelectedPapers"
import FullListPapers from "./pages/FullListPapers"
import SelectedAwards from "./pages/SelectedAwards"
import Services from "./pages/Services"
import Footer from "./pages/Footer"
import StatCounter from './hooks/StatCounter.js';


function App() {
    const fontSizes = { 'large': '24px', 'medium': '20px', 'small': '16px' };

    return (
        <Router>
            <Box bg="#1F1F21" minHeight="100vh">
                <Routes>
                    <Route exact path="/" element={
                        <>
                            <Header fontSizes={fontSizes} />
                            <Bio fontSizes={fontSizes} />
                            <Intro fontSizes={fontSizes} />
                            <SelectedPapers fontSizes={fontSizes} />
                            <SelectedAwards fontSizes={fontSizes} />
                            <Services fontSizes={fontSizes} />
                        </>
                    } />
                    <Route path="/fullpapers" element={<FullListPapers fontSizes={fontSizes} />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <StatCounter />
                <Footer/>
            </Box>
        </Router>
    );
}

export default App;