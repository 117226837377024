import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
	Box,
	Center,
    Flex,
    Spacer,
    HStack,
    VStack,
    Image,
    Text,
    Wrap,
    WrapItem,
    Link,
} from "@chakra-ui/react"

import papers from '../SelectedPapers/papers.json'
import PaperListId from '../SelectedPapers/PaperListId.js';
import useWindowSize from '../../hooks/useWindowSize'

function FullListPapers(props) {

    const { width } = useWindowSize();
    const lfs = props.fontSizes['large'];
    const sfs = props.fontSizes['small'];
    const paperIdsConferece = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18', 'C19'];
    const paperIdsJournal = ['J1', 'J2', 'J3', 'J4', 'J5', 'J6', 'J7', 'J8', 'J9','J10', "J11", "J12"];
    const paperIdsPreprint = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9'];

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
        >
            <Box
                h="100%"
                w="100%"
                maxWidth="928px"
                // bg="#000000"
                // borderTop="3px solid rgba(255, 255, 255, 0.1)"
                margin="36px 24px 0 24px"
            >
                {/* <HStack> */}
                <Text position="relative" fontSize={lfs} lineHeight="24px" color="#FFFFFF" fontWeight="500" margin="0 0 10px 10px">
                    Papers (<Link as={RouterLink}  to="/" color="teal.200">Home</Link>)
                </Text>
                <Box h="3px" w="100%" bg="rgba(255, 255, 255, 0.1)" />
                <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        Preprints
                    </Text>
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 20px 0px 30px" fontFamily="Catamaran">
                        <PaperListId papers={papers} paperIds={paperIdsPreprint} />
                    </Box>
                </Box>
                <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        Journal Publications
                    </Text>
                    {/* <Box h="2px" w="calc(100% - 100px)" bg="rgba(255, 255, 255, 0.1)" /> */}
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 20px 0px 30px" fontFamily="Catamaran">
                        <PaperListId papers={papers} paperIds={paperIdsJournal} />
                    </Box>
                </Box>
                <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        Conference Publications
                    </Text>
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 20px 0px 30px" fontFamily="Catamaran">
                        <PaperListId papers={papers} paperIds={paperIdsConferece} />
                    </Box>
                </Box>
            </Box >
		</Center>
	)
}

export default FullListPapers;