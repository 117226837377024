import React, { Component, useState } from 'react';
import {
	Box,
    Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
    Link,
    Center,
  } from "@chakra-ui/react"

import { ChevronDownIcon } from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom';

function HeaderMenuItem(props) {

    if (props.name === "Full List") {
        return (
            <Link as={RouterLink}  to="/fullpapers">
                <MenuItem
                    padding="0"
                    margin="0"
                    height="54px"
                    _hover={{bg:"#FFFFFF00"}}
                >
                <Center w="100%" h="100%"  >
                    <Center w="92%" h="100%"
                    _hover={{bg:"rgba(65,65,66)",color:"#FFFFFF",borderRadius: "10px"}}
                    >
                        <Box fontSize="20px"
                             position="absolute" 
                             left="30px"
                             fontFamily="Palanquin"
                             fontWeight="700"
                             fontColor="rgba(153,153,153)"
                        >
                            {props.name}
                        </Box>
                    </Center>
                </Center>
                </MenuItem>
            </Link>
        )
    }
    return (
        <Link href={props.link}  isExternal>
            <MenuItem
                padding="0"
                margin="0"
                height="54px"
                _hover={{bg:"#FFFFFF00"}}
            >
            <Center w="100%" h="100%"  >
                <Center w="92%" h="100%"
                _hover={{bg:"rgba(65,65,66)",color:"#FFFFFF",borderRadius: "10px"}}
                >
                    <Box fontSize="20px"
                         position="absolute" 
                         left="30px"
                         fontFamily="Palanquin"
                         fontWeight="700"
                         fontColor="rgba(153,153,153)"
                    >
                        {props.name}
                    </Box>
                </Center>
            </Center>
            </MenuItem>
        </Link>
    )
}


function HeaderMenuButton(props) {
	
    const [isHover, setIsHover] = useState(0)

    const menuItems = props.content.map((item)=>
        <HeaderMenuItem 
            key={item[0]} 
            name={item[0]} 
            link={item[1]}
            item={item}
        />
    )

    console.log(isHover)

    return (
        <Menu autoSelect={false}>
            {({ isOpen }) => (
                <>
                <Box justifyContent="center">
                    <MenuButton
                        top="22px"
                        as={Button}
                        bg="rgba(255, 255, 255, 0.00)"
                        fontSize="20px"
                        borderRadius="10px"
                        width={props.width}
                        height="54px"
                        borderColor="rgba(255, 255, 255, 0.03)"
                        color="#999999"
                        _hover={{
                            bg: "rgba(255, 255, 255, 0.1)", 
                            color:"#FFFFFF",
                            borderRadius: "10px",
                            }}
                        _active={{}}
                        _focus={{}}
                        _expanded={{
                            bg: "rgba(44,44,46)", 
                            color:"#FFFFFF",
                            borderRadius: "10px",
                            }}
                        fontFamily="Palanquin"
                        onMouseEnter={() => setIsHover(1)}
                        onMouseLeave={() => setIsHover(0)}
                    >
                        {props.name}  {(isOpen|isHover===1)?<ChevronDownIcon boxSize={7} color="#FFFFFF"/>:<ChevronDownIcon boxSize={7} color="#FFFFFF00"/>}
                    </MenuButton>
                </Box>
                <Box zIndex="9999">
                <MenuList 
                    margin="auto"
                    bg="rgba(44,44,46)"
                    borderRadius="10px"
                    width="218px"
                    color="#999999"
                    fontSize="20px"
                    fontWeight="500"
                    borderColor="rgba(255, 255, 255, 0.0)"
                    boxShadow="10px 20px 18px -10px rgba(14, 14, 15, 0.3)"
                >
                    {menuItems}
                </MenuList>
                </Box>
                </>
            )}
        </Menu>
    )  
}

export default HeaderMenuButton;