import React, { Component } from 'react';
import {
	Box,
	Center,
    Flex,
    Spacer,
    HStack,
    VStack,
    Image,
    Text,
    Wrap,
    WrapItem,
    Link,
} from "@chakra-ui/react"

import useWindowSize from '../../hooks/useWindowSize'
import { XIcon, GIcon, EmailIcon, ZhihuIcon, GithubIcon, IGIcon, ColabIcon } from '../../icons'

function Intro(props) {

    const { width } = useWindowSize();
    const lfs = props.fontSizes['large'];
    const sfs = props.fontSizes['small'];

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
        >
            <Box
                h="100%"
                w="100%"
                maxWidth="928px"
                // bg="#000000"
                // borderTop="3px solid rgba(255, 255, 255, 0.1)"
                margin="36px 24px 0 24px"
            >
                {/* <HStack> */}
                <Text position="relative" fontSize={lfs} lineHeight="24px" color="#FFFFFF" fontWeight="500" margin="0 0 10px 10px">
                    About
                </Text>
                <Box h="3px" w="100%" bg="rgba(255, 255, 255, 0.1)" />
                <Text fontFamily="Catamaran" position="relative" fontSize='18px' lineHeight="24px" color="#BBBBBB" fontWeight="400" margin="10px 10px 10px 10px" textAlign="justify">
                    Currently, my research focuses on developing neural network and machine learning algorithms, with a particular emphasis on practical applications in medical image analysis. This includes image registration, segmentation, classification, and solving inverse problems. I am passionately dedicated to building  <b><span style={{ color: "#EE7D85" }}>Ultrahand</span></b>, an innovative neuralized tool designed to bridge the gap between human and machine intelligence, enhancing clinical translations and outcomes.
                </Text>
            </Box >

		</Center>
	)
}

export default Intro;